<template>
    <div></div>
</template>
<script>
export default {
    data() {
        return {
            // websocket相关
            socketObj: "", // websocket实例对象
            //心跳检测
            heartCheck: {
                vueThis: this, // vue实例
                timeout: 10000, // 超时时间
                timeoutObj: null, // 计时器对象——向后端发送心跳检测
                serverTimeoutObj: null, // 计时器对象——等待后端心跳检测的回复
                // 心跳检测重置
                reset: function () {
                    clearTimeout(this.timeoutObj);
                    clearTimeout(this.serverTimeoutObj);
                    return this;
                },
                // 心跳检测启动
                start: function () {
                    this.timeoutObj && clearTimeout(this.timeoutObj);
                    this.serverTimeoutObj && clearTimeout(this.serverTimeoutObj);
                    this.timeoutObj = setTimeout(() => {
                        // 这里向后端发送一个心跳检测，后端收到后，会返回一个心跳回复
                        this.vueThis.socketObj.send("HeartBeat");
                        console.log("发送心跳检测");
                        this.serverTimeoutObj = setTimeout(() => {
                            // 如果超过一定时间还没重置计时器，说明websocket与后端断开了
                            console.log("未收到心跳检测回复");
                            // 关闭WebSocket
                            this.vueThis.socketObj.close();
                        }, this.timeout);
                    }, this.timeout);
                },
            },
            socketReconnectTimer: null, // 计时器对象——重连
            socketReconnectLock: false, // WebSocket重连的锁
            socketLeaveFlag: false, // 离开标记（解决 退出登录再登录 时出现的 多次相同推送 问题，出现的本质是多次建立了WebSocket连接）
        };
    },
    created() {
        console.log("离开标记", this.socketLeaveFlag);
    },
    mounted() {
        // websocket启动
        this.createWebSocket();
    },
    destroyed() {
        // 离开标记
        this.socketLeaveFlag = true;
        // 关闭WebSocket
        this.socketObj.close();
    },
    methods: {
        // websocket启动
        createWebSocket() {
            let webSocketLink = "wss://uat.sssyin.cn/ws-reservation"; // webSocket地址
            // console.log(webSocketLink);
            try {
                if ("WebSocket" in window) {
                    this.socketObj = new WebSocket(webSocketLink);
                } else if ("MozWebSocket" in window) {
                    this.socketObj = new MozWebSocket(webSocketLink);
                }
                // websocket事件绑定
                this.socketEventBind();
            } catch (e) {
                console.log("catch" + e);
                // websocket重连
                this.socketReconnect();
            }
        },
        // websocket事件绑定
        socketEventBind() {
            // 连接成功建立的回调
            this.socketObj.onopen = this.onopenCallback;
            // 连接发生错误的回调
            this.socketObj.onerror = this.onerrorCallback;
            // 连接关闭的回调
            this.socketObj.onclose = this.oncloseCallback;
            // 向后端发送数据的回调
            this.socketObj.onsend = this.onsendCallback;
            // 接收到消息的回调
            this.socketObj.onmessage = this.getMessageCallback;

            //监听窗口关闭事件，当窗口关闭时，主动去关闭websocket连接，防止连接还没断开就关闭窗口，server端会抛异常。
            window.onbeforeunload = () => {
                this.socketObj.close();
            };
        },
        // websocket重连
        socketReconnect() {
            if (this.socketReconnectLock) {
                return;
            }
            this.socketReconnectLock = true;
            this.socketReconnectTimer && clearTimeout(this.socketReconnectTimer);
            this.socketReconnectTimer = setTimeout(() => {
                console.log("WebSocket:重连中...");
                this.socketReconnectLock = false;
                // websocket启动
                this.createWebSocket();
            }, 4000);
        },
        // 连接成功建立的回调
        onopenCallback: function (event) {
            console.log("WebSocket:已连接");
            // 心跳检测重置
            this.heartCheck.reset().start();
        },
        // 连接发生错误的回调
        onerrorCallback: function (event) {
            console.log("WebSocket:发生错误");
            // websocket重连
            this.socketReconnect();
        },
        // 连接关闭的回调
        oncloseCallback: function (event) {
            console.log("WebSocket:已关闭");
            // 心跳检测重置
            this.heartCheck.reset();
            if (!this.socketLeaveFlag) {
                // 没有离开——重连
                // websocket重连
                this.socketReconnect();
            }
        },
        // 向后端发送数据的回调
        onsendCallback: function () {
            console.log("WebSocket:发送信息给后端");
        },
        // 接收到消息的回调
        getMessageCallback: function (msg) {
            // console.log(msg);
            console.log(msg.data);
            if (msg.data.indexOf("HeartBeat") > -1) {
                // 心跳回复——心跳检测重置
                // 收到心跳检测回复就说明连接正常
                console.log("收到心跳检测回复");
                // 心跳检测重置
                this.heartCheck.reset().start();
            } else {
                // 普通推送——正常处理
                console.log("收到推送消息");
                let data = JSON.parse(msg.data);
                // 相关处理
                console.log(data);
            }
        },
    },
};
</script>